import * as React from "react"
import { Box, Button, Flex, Image, Stack, Text, useColorModeValue } from "@chakra-ui/react"
import NextLink from "next/link"

export function Kompetitions() {
  const color = useColorModeValue("gray.700", "gray.400")
  return (
    <Flex align="center" h={{ base: "auto", md: "100vh" }} flexDir={{ base: "column-reverse", md: "row" }}>
      <Box
        pos="relative"
        width={{ base: "100%", md: "52%" }}
        h={{ base: "50vh", md: "73%" }}
        mr={{ base: 0, md: 20 }}
      >
        <Image
          alt="wedstrijdafbeelding 1"
          src="/competition-1.png"
          w={{ base: "200px", md: "340px" }}
          position="absolute"
          top={{ base: 0, lg: 0 }}
          left={{ base: 0, lg: 0 }}
          zIndex={{ base: 1, md: 0 }}
          borderRadius={16}
          boxShadow="0px 11px 46px 2px rgba(0, 0, 0, 0.3)"
        />
        <Image
          alt="wedstrijdafbeelding 2"
          src="/competition-2.png"
          w={{ base: "200px", md: "340px" }}
          position="absolute"
          bottom={{ base: 0, lg: 0 }}
          right={{ base: 0, lg: 0 }}
          zIndex={{ base: 0, md: 1 }}
          borderRadius={16}
          boxShadow="0px 11px 46px 2px rgba(0, 0, 0, 0.3)"
        />
      </Box>

      <Stack w={{ base: "100%", md: "40%" }} spacing={10} mb={{ base: 20, md: 0 }}>
        <Text fontSize={{ base: 32, md: 48 }} fontWeight={900} lineHeight="shorter" role="heading">
          <Text as="span" color="yellow.500" role="heading">
            Wedstrijd
          </Text>{" "}
          om te shinen op het juiste moment
        </Text>
        <Text color={color} fontSize={{ base: "lg", md: "2xl" }} lineHeight="short" role="heading">
          Klaar voor het podium en publiek? Doe dan ervaring op tijdens de voorronde in jouw provincie. Je
          krijgt feedback van een jury. Win je de voorronde? Dan ga je naar de finale. Als je die wint, mag je
          jouw werk showen op een van onze mainstages, van Oerol tot het Stedelijk Museum Amsterdam.
        </Text>

        <Button
          as={NextLink}
          passHref
          href="/wedstrijd"
          fontSize={{ base: 16, md: 22 }}
          px={{ base: 8, md: 8 }}
          py={{ base: 6, md: 8 }}
          colorScheme="yellow"
          w="fit-content"
        >
          BEKIJK
        </Button>
      </Stack>
    </Flex>
  )
}
